import { useLoaderData } from "react-router-dom";
import Page from "../../components/Page";
import CodeBlock from "./components/CodeBlock";
import FaqBlock from "./components/FaqBlock";
import PayoutHistoryBlock from "./components/PayoutHistoryBlock";
import StatisticsBlock from "./components/StatisticsBlock";
import type { loader } from "./route";

const Dashboard = () => {
  const loaderData = useLoaderData() as Awaited<ReturnType<typeof loader>>;
  const agent = loaderData && "data" in loaderData ? loaderData.data : null;

  if (!agent) {
    return <div>Error has occurred</div>;
  }

  return (
    <>
      <Page showProfile>
        <div className="grid md:[grid-template-columns:_1fr_1fr] lg:[grid-template-columns:_1fr_2fr] gap-8">
          <FaqBlock className="md:col-start-1" />
          <CodeBlock
            infoText={agent?.infoText}
            loading={false}
            className="md:col-start-1"
            isActive={agent.isActive}
          />
          <StatisticsBlock className="md:col-start-1" />
          <PayoutHistoryBlock
            className="md:col-start-2 md:row-span-full md:row-end-4"
            firstName={agent?.firstName}
            lastName={agent?.lastName}
            email={agent?.email}
            sortCode={agent?.sortCode}
            accountNumber={agent?.accountNumber}
            isActive={agent.isActive}
          />
        </div>
      </Page>
    </>
  );
};

export default Dashboard;
