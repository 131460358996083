import { Form, useActionData } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/Button";
import { H1, H4 } from "../../components/Heading";
import LoginWrapper from "../../components/LoginWrapper";
import Page from "../../components/Page";
import TextInput from "../../components/TextInput";
import emailValidator from "../../helpers/validators/emailValidator";
import { media } from "../../styles";
import type { action } from "./route";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;

  ${media.desktop} {
    width: 35rem;
  }
`;

const ErrorText = styled.span`
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  font-weight: 700;
  color: ${(props) => props.theme.error};
`;

const Text = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.9rem;
  letter-spacing: 0.015rem;
`;

const EmailInput = styled(TextInput)`
  background-color: ${(props) => props.theme.background};
`;

const LoginEmail = () => {
  const actionData = useActionData() as Awaited<ReturnType<typeof action>>;
  const actionError = actionData && "error" in actionData ? actionData : null;

  return (
    <Page>
      <LoginWrapper>
        <H1>Agents reward programme</H1>
        <Content>
          <H4>Become Wectory’s partner in a minute</H4>
          <Form method="post" className="space-y-8">
            <EmailInput
              name="email"
              validator={emailValidator}
              validationError="Email is not valid"
              placeholder="Your email"
            />
            {actionError?.error && <ErrorText>{actionError?.error}</ErrorText>}
            <Button type="submit">Next →</Button>
          </Form>
          <Text>
            We will send a special code to this email to confirm your account
          </Text>
        </Content>
      </LoginWrapper>
    </Page>
  );
};

export default LoginEmail;
