import styled from "styled-components";
import { H1 } from "../../../../components/Heading";
import BaseBlock from "../BaseBlock";
import Accordion from "./components/Accordion";
import { usePayoutHistory } from "./hooks";
import AddUserData, { AddUserDataProps } from "./components/add-user-data";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { useState } from "react";
import { Skeleton } from "@/components/ui/skeleton";

type PayoutHistoryBlockProps = {
  error?: string;
  className?: string;
  loading?: boolean;
  isActive: boolean;
} & Omit<AddUserDataProps, "close">;

const Block = styled(BaseBlock)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const PayoutHistoryBlock = ({
  error,
  firstName,
  lastName,
  email,
  sortCode,
  accountNumber,
  className,
  loading,
  isActive,
}: PayoutHistoryBlockProps) => {
  const { payments, error: dataError } = usePayoutHistory();
  const [isOpen, setIsOpen] = useState(false);

  if (!isActive) {
    return (
      <Block className={className}>
        <H1>Account verification in progress</H1>
        <p className="leading-normal md:leading-relaxed font-bold md:text-3xl border-l-8 pl-4 text-balance border-red-300">
          Exciting times ahead! 🎉 We&apos;re currently verifying your account.
        </p>
        <p className="leading-normal md:leading-relaxed font-bold md:text-3xl pl-4 text-balance">
          Once complete, you&apos;ll unlock the power to generate referral codes
          and start earning those sweet commission payments. Hang tight, it
          won&apos;t be long now!
        </p>
      </Block>
    );
  }

  return (
    <Block className={className}>
      <H1>Payout history</H1>
      {loading ? (
        <div className="space-y-4">
          <Skeleton className="w-full h-8" />
          <Skeleton className="w-full h-8" />
          <Skeleton className="w-full h-8" />
          <Skeleton className="w-full h-8" />
        </div>
      ) : (
        <>
          {!sortCode && (
            <p className="leading-normal md:leading-relaxed font-bold md:text-3xl border-l-8 pl-4 text-balance border-red-300">
              Please{" "}
              <Dialog open={isOpen} onOpenChange={setIsOpen}>
                <DialogTrigger asChild>
                  <span className="underline underline-offset-4 md:underline-offset-8 text-accent cursor-pointer">
                    add your banking details
                  </span>
                </DialogTrigger>
                <DialogContent className="!rounded-3xl">
                  <AddUserData
                    {...{ firstName, lastName, email, sortCode, accountNumber }}
                    close={() => setIsOpen(false)}
                  />
                </DialogContent>
              </Dialog>{" "}
              to start receiving your commission
            </p>
          )}

          {payments ? (
            <Accordion items={payments} />
          ) : (
            dataError || "No payments yet"
          )}
        </>
      )}
    </Block>
  );
};

export default PayoutHistoryBlock;
