import styled from "styled-components";
import Button from "../../components/Button";
import { H1 } from "../../components/Heading";
import LoginWrapper from "../../components/LoginWrapper";
import Page from "../../components/Page";
import TextInput from "../../components/TextInput";
import { stopPropagationOnClick } from "../../helpers";
import { media } from "../../styles";
import z from "zod";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useFetcher, useNavigation, useSubmit } from "react-router-dom";
import { Checkbox } from "@/components/ui/checkbox";
import { debounce } from "@/helpers/debounce";
import FormError from "@/components/form-error";
import { UK_COMPANY_NUMBER } from "./utils/CRNRegexp";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;

  ${media.desktop} {
    width: 35.3rem;
  }
`;

const Input = styled(TextInput)`
  background-color: ${(props) => props.theme.background};
`;

const schema = z.object({
  firstName: z
    .string()
    .min(1, "First name is required")
    .regex(/^[a-zA-Z\s]+$/, "Only letters and spaces are allowed"),
  lastName: z
    .string()
    .min(1, "Last name is required")
    .regex(/^[a-zA-Z\s]+$/, "Only letters and spaces are allowed"),
  companyWebsite: z
    .string()
    .min(1, {
      message: "Company website is required",
    })
    .refine(
      (value) => {
        const urlPattern =
          /^(?:https?:\/\/)?(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/\S*)?$/;
        return urlPattern.test(value);
      },
      {
        message: "Please enter a valid website URL (e.g., www.example.com)",
      },
    ),
  companyRegistrationNumber: z.string().refine((val) => val.length > 0, {
    message: "Company registration number is required",
  }),
  agreement: z.boolean().refine((val) => val === true, {
    message: "You must agree to the terms and conditions",
  }),
});

type Schema = z.infer<typeof schema>;

const resolver = zodResolver(schema);

const InlineLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.accent};
`;

InlineLink.defaultProps = {
  target: "_blank",
  onClick: stopPropagationOnClick,
};

const Registration = () => {
  const [crnValid, setCrnValid] = useState(false);

  const form = useForm<Schema>({
    resolver: resolver,
    defaultValues: {
      firstName: "",
      lastName: "",
      companyWebsite: "",
      companyRegistrationNumber: "",
      agreement: false,
    },
  });

  const submit = useSubmit();

  const onSubmit: SubmitHandler<Schema> = (data) => {
    if (crnValid) {
      submit(data, {
        method: "post",
      });
    } else {
      form.setError("companyRegistrationNumber", {
        type: "manual",
        message: "Please enter a valid company registration number",
      });
    }
  };

  const navigation = useNavigation();
  const submitting = navigation.state === "submitting";

  const crnFetcher = useFetcher({ key: `crn` });

  const [companyName, setCompanyName] = useState("");

  useEffect(() => {
    if (crnFetcher.data) {
      setCompanyName(crnFetcher.data);
      setCrnValid(true);
    } else {
      setCrnValid(false);
    }
  }, [crnFetcher.data]);

  const debouncedSubmit = debounce((crn) =>
    crnFetcher.submit(
      { crn: crn as string },
      {
        method: "get",
        action: `/api/company-check`,
      },
    ),
  );

  const checkCompanyByCrn = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName("");
    setCrnValid(false);

    if (UK_COMPANY_NUMBER.test(e.target.value)) {
      debouncedSubmit(e.target.value);
    }
  };

  return (
    <Page>
      <LoginWrapper>
        <H1>Create your account</H1>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          <Controller
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <div className="space-y-2">
                <Input
                  {...field}
                  placeholder="Your First name"
                  disabled={submitting}
                />
                <FormError
                  errorMessage={form.formState.errors.firstName?.message}
                />
              </div>
            )}
          />

          <Controller
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <div className="space-y-2">
                <Input
                  {...field}
                  placeholder="Your Surname"
                  disabled={submitting}
                />
                <FormError
                  errorMessage={form.formState.errors.lastName?.message}
                />
              </div>
            )}
          />

          <Controller
            control={form.control}
            name="companyWebsite"
            render={({ field }) => (
              <div className="space-y-2">
                <Input
                  {...field}
                  placeholder="Company website"
                  disabled={submitting}
                />
                <FormError
                  errorMessage={form.formState.errors.companyWebsite?.message}
                />
              </div>
            )}
          />

          <Controller
            control={form.control}
            name="companyRegistrationNumber"
            render={({ field }) => (
              <div className="space-y-4">
                <Input
                  {...field}
                  placeholder="Company registration number"
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, "");
                    field.onChange(numericValue);
                    checkCompanyByCrn({
                      ...e,
                      target: { ...e.target, value: numericValue },
                    });
                  }}
                  disabled={submitting}
                />
                {field.value?.length >= 8 && (
                  <span className="text-xl inline-block">
                    {crnFetcher.state === "loading"
                      ? "Searching..."
                      : companyName || "Company not found"}
                  </span>
                )}
                <FormError
                  errorMessage={
                    form.formState.errors.companyRegistrationNumber?.message ||
                    (!crnValid && field.value && form.formState.isSubmitted
                      ? "Please enter a valid company registration number"
                      : undefined)
                  }
                />
              </div>
            )}
          />

          <Controller
            control={form.control}
            name="agreement"
            render={({ field }) => {
              return (
                <div className="space-y-2">
                  <div className="flex gap-4 items-start">
                    <Checkbox
                      {...field}
                      value=""
                      id="agreement"
                      defaultChecked={false}
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                    <label
                      htmlFor="agreement"
                      className="text-xl -top-[6px] relative leading-8 font-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      By registering, you are agreeing to our{" "}
                      <InlineLink href="https://www.wectory.com/pp">
                        Privacy&nbsp;Policy
                      </InlineLink>{" "}
                      and signing our{" "}
                      <InlineLink href="https://www.wectory.com/ara">
                        Terms&nbsp;of&nbsp;Use
                      </InlineLink>
                      {"."}
                    </label>
                  </div>
                  <FormError
                    errorMessage={form.formState.errors.agreement?.message}
                  />
                </div>
              );
            }}
          />

          <Button type="submit" disabled={submitting}>
            Done
          </Button>
        </Form>
      </LoginWrapper>
    </Page>
  );
};

export default Registration;
