// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  fetchAndActivate,
  getRemoteConfig,
  getValue,
} from "firebase/remote-config";

const config = {
  GRAPHQL_URL:
    import.meta.env.VITE_REACT_APP_GRAPHQL_URL || "http://localhost:4000",
};
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: import.meta.env.VITE_API_KEY,
  authDomain: import.meta.env.VITE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_APP_ID,
  measurementId: import.meta.env.VITE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export enum REMOTE_CONFIG_KEYS {
  UPLOAD_FILE_SIZE_LIMIT = "upload_file_size_limit",
}

const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
remoteConfig.defaultConfig = {
  [REMOTE_CONFIG_KEYS.UPLOAD_FILE_SIZE_LIMIT]: 1,
};

fetchAndActivate(remoteConfig);

export const getRemoteValue = (key: REMOTE_CONFIG_KEYS) =>
  getValue(remoteConfig, key);

export default config;
