import type { RouteObject } from "react-router-dom";
import PaymentSetup from ".";
import PaymentSetupFixed from "./PaymentSetupFixed";
import PaymentSetupFinished from "./PaymentSetupSuccess";

export const paymentSetupRoute: RouteObject = {
  path: "/payment-setup/:id",
  element: <PaymentSetup />,
};

export const paymentSetupFinishedRoute: RouteObject = {
  path: "/payment-setup/success",
  element: <PaymentSetupFinished />,
};

export const paymentSetupFailedRoute: RouteObject = {
  path: "/payment-setup/failed",
  element: <PaymentSetupFinished failed />,
};

export const paymentSetupFixedRoute: RouteObject = {
  path: "/payment-setup/to-the-landlord",
  element: <PaymentSetupFixed />,
};
