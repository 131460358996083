type FormErrorType = {
  errorMessage?: string;
};

const FormError = ({ errorMessage }: FormErrorType) => {
  if (!errorMessage) return null;
  return (
    <span className="text-destructive inline-block text-xl">
      {errorMessage}
    </span>
  );
};

export default FormError;
