import type { ActionFunctionArgs, RouteObject } from "react-router-dom";
import { redirect } from "react-router-dom";

import graphqlClient from "@/datasources/graphql/client";
import {
  GetAgentQueryDocument,
  RegisterAgentMutationDocument,
} from "@/datasources/graphql/operations";
import { formatError } from "@/helpers/format-error";

import Registration from "./registration";

export async function loader() {
  const { data, error } = await graphqlClient.query({
    query: GetAgentQueryDocument,
  });

  if (error) {
    console.error(formatError(error));
    return null;
  }

  if (data && data.agent && data.agent.firstName && data.agent.lastName) {
    return redirect("/");
  }

  return null;
}

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();

  const website = (formData.get("companyWebsite") as string).trim();
  const formattedWebsite =
    website.startsWith("http://") || website.startsWith("https://")
      ? website
      : `http://${website}`;

  const agentData = {
    firstName: (formData.get("firstName") as string).trim(),
    lastName: (formData.get("lastName") as string).trim(),
    crn: (formData.get("companyRegistrationNumber") as string).trim(),
    website: formattedWebsite,
  };

  try {
    const { data } = await graphqlClient.mutate({
      mutation: RegisterAgentMutationDocument,
      variables: { agentData },
    });

    if (
      data?.fillAgentBasicInfo?.firstName &&
      data?.fillAgentBasicInfo?.lastName
    ) {
      return redirect("/");
    }
  } catch (error) {
    return { error: "Registration failed. Please try again." };
  }

  return null;
}

export const registrationRoute: RouteObject = {
  path: "/registration",
  element: <Registration />,
  loader,
  action,
};
