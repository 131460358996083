import styled from "styled-components";
import CodeInput from "../../components/CodeInput";
import { H1, H4 } from "../../components/Heading";
import LoginWrapper from "../../components/LoginWrapper";
import Page from "../../components/Page";
import { media } from "../../styles";
import { useConfirm } from "./hooks";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";

const Wrapper = styled(LoginWrapper)`
  width: clamp(32.6rem, 90vw, 100%);
  margin: 0 auto;

  ${media.desktop} {
    width: 50%;
    min-width: 59rem;
    margin: unset;
  }
`;

const CodeBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: clamp(32.6rem, 100%, 40rem);

  ${media.desktop} {
    width: 32.6rem;
  }
`;

const Timer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.4rem;
  letter-spacing: 0.015em;
  line-height: 1.9rem;
  color: #aeaeae;
`;

const ResendLink = styled.div`
  width: max-content;
  font-size: 1.4rem;
  font-weight: 700;
  color: ${(props) => props.theme.accent};
  cursor: pointer;
`;

const Error = styled.div`
  width: max-content;
  font-size: 1.6rem;
  font-weight: 900;
  color: ${(props) => props.theme.error};
`;

const Confirm = () => {
  const {
    email,
    code,
    fillCode,
    resendCode,
    timer,
    timerFinished,
    error,
    loading,
  } = useConfirm(60);

  const isEmailDuplicate = error === "Email already used by another user";

  return (
    <Page>
      <Wrapper>
        <H1>Enter the code</H1>
        <H4>{`We sent the confirmation code to ${email}`}</H4>
        <CodeBlock>
          <CodeInput code={code} onFill={fillCode} disabled={loading} />
          <Timer>
            {error && (
              <Error className="max-w-[326px]">
                {isEmailDuplicate
                  ? "It looks like this email address is already associated with an account in one of our other products."
                  : error}
              </Error>
            )}
            {isEmailDuplicate ? (
              <Button asChild>
                <Link
                  to="/login"
                  className="block self-start mt-8 text-2xl text-primary"
                >
                  Try different email
                </Link>
              </Button>
            ) : timerFinished ? (
              <ResendLink onClick={resendCode}>Resend code</ResendLink>
            ) : (
              `Send again in ${timer.minutes}:${timer.seconds}`
            )}
          </Timer>
        </CodeBlock>
      </Wrapper>
    </Page>
  );
};

export default Confirm;
