import { ApolloError } from "@apollo/client";
import { getEmail } from "../../helpers";

const formatError = (error: ApolloError | undefined): string | null => {
  if (!error) return null;

  if (error.graphQLErrors.length > 0) {
    const err = error.graphQLErrors[0];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (typeof err.extensions.response?.body === "string") {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      return err.extensions.response.body;
    }
    switch (err.extensions.code) {
      case "FORBIDDEN":
        return "Invalid token";
      case "UNAUTHENTICATED":
        return "Session expired";
      default:
        return "Unknown error";
    }
  } else if (error.networkError) {
    return "Network error";
  }
  return "Unknown error";
};

const emailOrUnsaved = () => {
  const email = getEmail() || "your email";
  const unsavedData = JSON.parse(
    localStorage.getItem("unsaved_data") || "null",
  );
  if (unsavedData && unsavedData.email && email !== unsavedData.email) {
    return unsavedData.email;
  }
  return email;
};

export { emailOrUnsaved, formatError };
