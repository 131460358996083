import graphqlClient from "@/datasources/graphql/client";
import type { ActionFunctionArgs, RouteObject } from "react-router-dom";
import { redirect } from "react-router-dom";
import { LoginMutationDocument } from "../../datasources/graphql/operations";
import { getFingerprint } from "../../helpers";
import { saveToken, TokenType } from "../../helpers/jwt";
import LoginEmail from "./login-email";

interface ErrorExtensions {
  code: string;
  response: {
    body:
      | {
          errors: string[];
        }
      | any;
  };
  [attributeName: string]: unknown;
}

export const loader = async () => {
  const token = localStorage.getItem("auth_access_token");

  if (token) {
    return redirect("/");
  }

  return null;
};

export const action = async ({ request }: ActionFunctionArgs) => {
  const formData = await request.formData();
  const email = formData.get("email") as string;

  try {
    const { data } = await graphqlClient.mutate({
      mutation: LoginMutationDocument,
      variables: { email, fingerprint: getFingerprint() },
    });

    if (data?.auth) {
      saveToken(TokenType.LOGIN, data.auth);
      return redirect(`/confirm${document.location.search || ""}`);
    } else {
      return { error: "Something went wrong" };
    }
  } catch (error: any) {
    if (error && error.graphQLErrors && error.graphQLErrors.length > 0) {
      const extensions = error.graphQLErrors[0].extensions as ErrorExtensions;
      if (
        extensions &&
        extensions.code === "INTERNAL_SERVER_ERROR" &&
        extensions.response.body.errors
      ) {
        redirect("/error");
      }
    }
    // Handle specific errors if needed
    return { error: error.message };
  }
};

export const loginEmailRoute: RouteObject = {
  path: "/login",
  element: <LoginEmail />,
  loader,
  action,
};
