import graphqlClient from "@/datasources/graphql/client";
import { GetAgentQueryDocument } from "@/datasources/graphql/operations";
import type { RouteObject } from "react-router-dom";
import { redirect } from "react-router-dom";
import Dashboard from "./dashboard";

export const loader = async () => {
  if (!localStorage.getItem("auth_access_token")) {
    return redirect("/login");
  }

  try {
    const { data, error } = await graphqlClient.query({
      query: GetAgentQueryDocument,
      fetchPolicy: "network-only",
      errorPolicy: "none",
    });

    if (error) {
      console.error(error.message);
      return {
        error: error.message,
        data: null,
      };
    }

    if (!(data?.agent?.firstName && data?.agent?.lastName)) {
      return redirect("/registration");
    }

    localStorage.setItem("agency", data.agent.agency ? "1" : "0");

    return {
      data: {
        firstName: data.agent.firstName ?? "",
        lastName: data.agent.lastName ?? "",
        email: data.agent.email ?? "",
        code: data.agent.rewardsCode ?? "",
        sortCode: data.agent.sortCode ?? "",
        accountNumber: data.agent.accountNumber ?? "",
        companyName: data.agent.agency?.title ?? null,
        infoText: data.agent.infoText ?? "",
        isActive: data.agent.isActive ?? false,
      },
      error: null,
    };
  } catch (error) {
    console.error(error);
    return {
      error: error as string,
      data: null,
    };
  }
};

export const dashboardRoute: RouteObject = {
  path: "/",
  element: <Dashboard />,
  loader: loader,
};
