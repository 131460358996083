import { ApolloError, ServerError } from "@apollo/client";

export const formatError = (error: ApolloError | undefined): string | null => {
  if (!error) return null;

  if (error.graphQLErrors.length > 0) {
    const err = error.graphQLErrors[0];
    switch (err.extensions.code) {
      case "INTERNAL_SERVER_ERROR":
        return "Something went wrong";
      default:
        return "Unknown error";
    }
  } else if (error.networkError) {
    const err = error.networkError as ServerError;
    switch (err.statusCode) {
      case 400:
        return "Data error";
      default:
        return "Network error";
    }
  }

  if (error.message) {
    return error.message;
  }

  return "Unknown error";
};
