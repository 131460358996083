import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import ModalProvider from "./components/ModalProvider";
import { confirmRoute } from "./Routes/Confirm/route";
import { dashboardRoute } from "./Routes/Dashboard/route";
import { errorPageRoute } from "./Routes/ErrorPage/route";
import { loginEmailRoute } from "./Routes/LoginEmail/route";
import NotFound from "./Routes/NotFound";
import {
  paymentSetupFailedRoute,
  paymentSetupFinishedRoute,
  paymentSetupFixedRoute,
  paymentSetupRoute,
} from "./Routes/PaymentCheck/route";
import { registrationRoute } from "./Routes/Registration/route";
import { companyCheckRoute } from "./Routes/api/company-check";

const theme = {
  accent: "#4F4CFF",
  pageBackground: "#F6F6F6",
  background: "#FFFFFF",
  textMain: "#27252A",
  textSecondary: "#AEAEAE",
  error: "#FF3838",
};

const router = createBrowserRouter([
  dashboardRoute,
  loginEmailRoute,
  confirmRoute,
  registrationRoute,
  paymentSetupRoute,
  paymentSetupFinishedRoute,
  paymentSetupFailedRoute,
  paymentSetupFixedRoute,
  errorPageRoute,
  {
    path: "/api",
    children: [companyCheckRoute],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ModalProvider>
        <RouterProvider router={router} />
      </ModalProvider>
    </ThemeProvider>
  );
}

export default App;
