import graphqlClient from "@/datasources/graphql/client";
import { GetCompanyNameByCrnQueryDocument } from "@/datasources/graphql/operations";
import { formatError } from "@/helpers/format-error";
import { ApolloError } from "@apollo/client";
import { LoaderFunctionArgs, RouteObject } from "react-router-dom";

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const crn = url.searchParams.get("crn") ?? "";

  try {
    const { data } = await graphqlClient.query({
      query: GetCompanyNameByCrnQueryDocument,
      variables: {
        crn,
      },
    });

    return data.searchCompany.name;
  } catch (error) {
    console.error(formatError(error as ApolloError));
    return null;
  }
};

export const companyCheckRoute: RouteObject = {
  path: "company-check",
  loader,
};
