import { InputProps } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Trash2, Upload } from "lucide-react";
import { useState } from "react";

type FileInputProps = InputProps & {
  maxFileSize: number;
  onFieldChange: (files: File[]) => void;
};

const FileInput = (props: FileInputProps) => {
  const [files, setFiles] = useState<File[]>([]);
  const [tooBigFiles, setTooBigFiles] = useState<string[]>([]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTooBigFiles([]);
    if (e.target.files) {
      const tooBigFiles: string[] = [];
      const newFiles = Array.from(e.target.files).filter((file) => {
        const fileSizeMB = file.size / (1024 * 1024); // size in MB
        if (fileSizeMB > props.maxFileSize) {
          tooBigFiles.push(file.name);
          return false;
        }
        return true;
      });

      if (tooBigFiles.length > 0) {
        setTooBigFiles(tooBigFiles);
      }

      const filesToSet = [...Array.from(newFiles), ...files];
      setFiles(filesToSet);
      props?.onFieldChange(filesToSet);
    }
  };

  const handleFileDelete = (index: number) => {
    const filesWithoutDeleted = files.filter((_, i) => i !== index);
    setFiles(filesWithoutDeleted);

    props?.onFieldChange(filesWithoutDeleted);
  };

  return (
    <div className="flex flex-wrap gap-4 items-stretch">
      <div className="w-full">
        <input
          type="file"
          name="file"
          multiple
          {...props}
          id="file"
          onChange={handleFileChange}
          className="hidden"
        />
        <Label
          htmlFor="file"
          className="w-full h-full min-h-[160px] px-8 text-balance flex justify-center items-center border-2 border-dashed rounded-lg cursor-pointer"
        >
          Click or drag and drop to upload documents{" "}
          <Upload size={16} className="ml-4" />
        </Label>
      </div>

      {tooBigFiles.length > 0 &&
        tooBigFiles.map((name) => (
          <p className="text-xl text-destructive font-medium" key={name}>
            File {name} size exceeds {props.maxFileSize}MB
          </p>
        ))}
      {files.length > 0 && (
        <div className="w-full">
          <span className="text-xl">Choosed files:</span>
          <ul className="mt-4 mb-0 w-[50%]">
            {files.map((file, index) => (
              <li
                key={index}
                className="max-w-lg min-w-[50%] mb-4 last:mb-0 justify-between flex"
              >
                <span
                  className="text-2xl inline-block max-w-md text-ellipsis whitespace-nowrap overflow-hidden"
                  title={file.name}
                >
                  {index + 1}. {file.name}
                </span>
                <button
                  type="button"
                  onClick={() => handleFileDelete(index)}
                  title="Delete"
                >
                  <Trash2 size={16} />
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FileInput;
